import * as ActionTypes from './actionType';

const initState = {
  isloading: false,
  haslogin: false,
  errMsg: "",
  data: null
};

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_LOADING: {
      return { 
        isloading: true,
        haslogin: false,
        errMsg: "",
        data: {}
      };
    }

    case ActionTypes.USER_LOGIN: {
      //console.log('profileReducer USER_LOGIN ', action.payload.user);
      return { 
        isloading: false,
        haslogin: true,
        errMsg: "",
        data: action.payload.user 
      };
    }

    case ActionTypes.USER_LOGIN_ERROR: {
      return { 
        isloading: false,
        haslogin: false,
        errMsg: action.payload.errMsg,
        data: {}
      };
    }

    case ActionTypes.USER_LOGOUT: {
      return initState;
    }

    default:
      return state;
  }
};

