import * as ActionTypes from './actionType';

const initTableState = {
  pagination: {'current': 1, 'pageSize': 20, 'showSizeChanger': true, 'showQuickJumper': true},
  filters: {}, 
  sorter: {}, 
  extra: {},
  tab: undefined
};

export default function tableParamReducer(state = initTableState, action) {
  switch (action.type) {
    case ActionTypes.TABLE_INIT: {
      return initTableState;
    }

    case ActionTypes.TABLE_STORE: {
      //console.log('tableParamReducer TABLE_STORE pagination: ', action.payload.pagination);
      //console.log('tableParamReducer TABLE_STORE tab: ', action.payload.tab);
      return { 
        pagination: { 'current': action.payload.pagination.current, 
          'pageSize': action.payload.pagination.pageSize, 
          'showSizeChanger': action.payload.pagination.showSizeChanger, 
          'showQuickJumper': action.payload.pagination.showQuickJumper },
        filters: action.payload.filters, 
        sorter: action.payload.sorter, 
        extra: action.payload.extra,
        tab: action.payload.tab
      };
    }

    default:
      return state;
  }
};

