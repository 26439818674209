import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reducer/configureStore';

import { ModuleID, ModuleInfo } from './configurations/pageInfo/frontendModule';

const PageLoginWithRouter = lazy(() => import('./page/login'));
const PageHome = lazy(() => import('./page/home'));
//const PageHomeWithRouter = lazy(() => import('./pageHome'));
const Page404 = lazy(() => import('./page/404'));

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div></div>}>
          <BrowserRouter>
            <Switch>
              <Route path={ ModuleInfo[parseInt(ModuleID.Login)].url } component={ PageLoginWithRouter } />
              <Route path='/home' component={PageHome} />
              <Route path='/404' component={Page404} />
              <Redirect from='/*' to='/login' />
            </Switch>        
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}

export default App;


/*import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App; */
