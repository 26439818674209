import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';

import storage from 'redux-persist/lib/storage';

import profileReducer from './profile';
import tableParamReducer from './tableParam';
//import currentReducer from './current';

// Store enhancer
const middlewares = [];
//if (process.env.NODE_ENV === 'development') { middlewares.push(logger); }
middlewares.push(thunk);

const profilePersistConfig = {
  key: 'profile',
  storage,
};

const tableParamPersistConfig = {
  key: 'tableParam',
  storage,
};

/*const currentPersistConfig = {
  key: 'current',
  storage,
};*/

const rootReducer = combineReducers({
  profile: persistReducer(profilePersistConfig, profileReducer),
  tableParam: persistReducer(tableParamPersistConfig, tableParamReducer),
  //profile: profileReducer,
  //current: persistReducer(currentPersistConfig, currentReducer),
});

const store = createStore(rootReducer, applyMiddleware(...middlewares));
const persistor = persistStore(store);

export { store, persistor };