export const winGeneral = {
  Name: 'General',
  CallName: 'General',
  Type: 'Basic',
  APIList: { DeleteFile: 'deleteFile' }
}

export const winAccountForm = {
  Name: 'AccountForm',
  CallName: 'AccountForm',
  Type: 'Basic',
  APIList: { List: 'listAccountFormBrief', Query: 'queryAccountForm', Add: 'addAccountForm', Modify: 'modifyAccountForm', Delete: 'deleteAccountForm', 
    SendToBack: 'sendToBackAccountForm', SendToNext: 'sendToNextAccountForm', Cancel: 'cancelAccountForm', Execute: 'executeAccountForm' }
}

export const winAccounts = {
  Name: 'Accounts',
  CallName: 'Accounts',
  Type: 'Basic',
  APIList: { List: 'listAccountsBrief', Query: 'queryAccounts', Add: 'addAccounts', Modify: 'modifyAccounts', Delete: 'deleteAccounts', Settle: 'settleAccounts' }
}

export const winBank = {
  Name: 'Bank',
  CallName: 'Bank',
  Type: 'Basic',
  APIList: { List: 'listBankBrief', Query: 'queryBank', Add: 'addBank', Modify: 'modifyBank', Delete: 'deleteBank' }
}

export const winBrand = {
  Name: 'Brand',
  CallName: 'Brand',
  Type: 'Basic',
  APIList: { List: 'listBrandBrief', Query: 'queryBrand', Add: 'addBrand', Modify: 'modifyBrand', Delete: 'deleteBrand' }
}

export const winBuildingCategory = {
  Name: 'BuildingCategory',
  CallName: 'BuildingCategory',
  Type: 'Basic',
  APIList: { List: 'listBuildingCategoryBrief', Query: 'queryBuildingCategory', Add: 'addBuildingCategory', Modify: 'modifyBuildingCategory', Delete: 'deleteBuildingCategory' }
}

export const winBuildingType = {
  Name: 'BuildingType',
  CallName: 'BuildingType',
  Type: 'Basic',
  APIList: { List: 'listBuildingTypeBrief', Query: 'queryBuildingType', Add: 'addBuildingType', Modify: 'modifyBuildingType', Delete: 'deleteBuildingType' }
}

export const winBusnissObject = {
  Name: 'BusnissObject',
  CallName: 'BusnissObject',
  Type: 'Basic',
  APIList: { List: 'listBusnissObjectBrief', Query: 'queryBusnissObject', Add: 'addBusnissObject', Modify: 'modifyBusnissObject', Delete: 'deleteBusnissObject', Link: 'linkBusnissObject', Unlink: 'unlinkBusnissObject' }
}

export const winBusnissObjectZone = {
  Name: 'Zone',
  CallName: 'BusnissObjectZone',
  Type: 'Basic',
  APIList: { List: 'listBusnissObjectZoneBrief', Query: 'queryBusnissObjectZone', Add: 'addBusnissObjectZone', Modify: 'modifyBusnissObjectZone', Delete: 'deleteBusnissObjectZone', Link: 'linkBusnissObjectZone', Unlink: 'unlinkBusnissObjectZone' }
}

export const winComingWay = {
  Name: 'ComingWay',
  CallName: 'ComingWay',
  Type: 'Basic',
  APIList: { List: 'listComingWayBrief', Query: 'queryComingWay', Add: 'addComingWay', Modify: 'modifyComingWay', Delete: 'deleteComingWay' }
}

export const winCommunicationWay = {
  Name: 'CommunicationWay',
  CallName: 'CommunicationWay',
  Type: 'Basic',
  APIList: { List: 'listCommunicationWayBrief', Query: 'queryCommunicationWay', Add: 'addCommunicationWay', Modify: 'modifyCommunicationWay', Delete: 'deleteCommunicationWay' }
}

export const winCompany = {
  Name: 'Company',
  CallName: 'Company',
  Type: 'Basic',
  APIList: { List: 'listCompanyBrief', Query: 'queryCompany', Add: 'addCompany', Modify: 'modifyCompany', Delete: 'deleteCompany' }
}

export const winContact = {
  Name: 'Contact',
  CallName: 'Contact',
  Type: 'Basic',
  APIList: { List: 'listContactBrief', Query: 'queryContact', Add: 'addContact', Modify: 'modifyContact', Delete: 'deleteContact' }
}

export const winContactRecord = {
  Name: 'ContactRecord',
  CallName: 'Contact Record',
  Type: 'Basic',
  APIList: { List: 'listContactRecordBrief', Query: 'queryContactRecord', Add: 'addContactRecord', Modify: 'modifyContactRecord', Delete: 'deleteContactRecord' }
}

export const winCustomer = {
  Name: 'Customer',
  CallName: 'Customer',
  Type: 'Basic',
  APIList: { List: 'listCustomerBrief', Query: 'queryCustomer', Add: 'addCustomer', Modify: 'modifyCustomer', Delete: 'deleteCustomer', Link: 'linkCustomer', Unlink: 'unlinkCustomer', ExportAll: 'exportAllCustomer' }
}

export const winCustomerLevel = {
  Name: 'CustomerLevel',
  CallName: 'CustomerLevel',
  Type: 'Basic',
  APIList: { List: 'listCustomerLevelBrief', Query: 'queryCustomerLevel', Add: 'addCustomerLevel', Modify: 'modifyCustomerLevel', Delete: 'deleteCustomerLevel' }
}

export const winDepartment = {
  Name: 'Department',
  CallName: 'Department',
  Type: 'Basic',
  APIList: { List: 'listDepartmentBrief', Query: 'queryDepartment', Add: 'addDepartment', Modify: 'modifyDepartment', Delete: 'deleteDepartment' }
}

export const winDistrict = {
  Name: 'District',
  CallName: 'District',
  Type: 'Basic',
  APIList: { List: 'listDistrictBrief', Query: 'queryDistrict', Add: 'addDistrict', Modify: 'modifyDistrict', Delete: 'deleteDistrict', Switch: 'switchDistrict' }
}

export const winDocument = {
  Name: 'Document',
  CallName: 'Document',
  Type: 'Basic',
  APIList: { List: 'listDocumentBrief', Query: 'queryDocument', Add: 'addDocument', Modify: 'modifyDocument', Delete: 'deleteDocument' }
}

export const winDocumentCategory = {
  Name: 'DocumentCategory',
  CallName: 'DocumentCategory',
  Type: 'Basic',
  APIList: { List: 'listDocumentCategoryBrief', Query: 'queryDocumentCategory', Add: 'addDocumentCategory', Modify: 'modifyDocumentCategory', Delete: 'deleteDocumentCategory' }
}

export const winGroup = {
  Name: 'Group',
  CallName: 'Group',
  Type: 'Basic',
  APIList: { List: 'listGroupBrief', Query: 'queryGroup' }
}

export const winInventory = {
  Name: 'Inventory',
  CallName: 'Inventory',
  Type: 'Basic',
  APIList: { List: 'listInventoryBrief', Query: 'queryInventory', Add: 'addInventory', Modify: 'modifyInventory', Delete: 'deleteInventory' }
}

export const winInvoice = {
  Name: 'Invoice',
  CallName: 'Invoice',
  Type: 'Basic',
  APIList: { List: 'listInvoiceBrief', Query: 'queryInvoice', Add: 'addInvoice', Modify: 'modifyInvoice', Delete: 'deleteInvoice', Switch: 'switchInvoice' }
}

export const winJobTitle = {
  Name: 'JobTitle',
  CallName: 'JobTitle',
  Type: 'Basic',
  APIList: { List: 'listJobTitleBrief', Query: 'queryJobTitle', Add: 'addJobTitle', Modify: 'modifyJobTitle', Delete: 'deleteJobTitle', Switch: 'switchJobTitle' }
}

export const winMaterial = {
  Name: 'Material',
  CallName: 'Material',
  Type: 'Basic',
  APIList: { List: 'listMaterialBrief', Query: 'queryMaterial', Add: 'addMaterial', Modify: 'modifyMaterial', Delete: 'deleteMaterial' }
}

export const winPermissionGroup = {
  Name: 'PermissionGroup',
  CallName: 'PermissionGroup',
  Type: 'Basic',
  APIList: { List: 'listPermissionGroupBrief', Query: 'queryPermissionGroup', Add: 'addPermissionGroup', Modify: 'modifyPermissionGroup', Delete: 'deletePermissionGroup' }
}

export const winProgressStatusLevel = {
  Name: 'ProgressStatusLevel',
  CallName: 'ProgressStatusLevel',
  Type: 'Basic',
  APIList: { List: 'listProgressStatusLevelBrief', Query: 'queryProgressStatusLevel', Add: 'addProgressStatusLevel', Modify: 'modifyProgressStatusLevel', Delete: 'deleteProgressStatusLevel' }
}

export const winProject = {
  Name: 'Project',
  CallName: 'Project',
  Type: 'Basic',
  APIList: { List: 'listProjectBrief', Query: 'queryProject', Add: 'addProject', Modify: 'modifyProject', Delete: 'deleteProject', Link: 'linkProject', Unlink: 'unlinkProject' }
}

export const winProjectContract = {
  Name: 'ProjectContract',
  CallName: 'ProjectContract',
  Type: 'Basic',
  APIList: { List: 'listProjectContractBrief', Query: 'queryProjectContract', Add: 'addProjectContract', Modify: 'modifyProjectContract', Delete: 'deleteProjectContract', Export: 'exportProjectContract', Transfer: 'transferProjectContract', Reset: 'resetProjectContract' }
}

export const winProjectContractCategory = {
  Name: 'ProjectContractCategory',
  CallName: 'ProjectContractCategory',
  Type: 'Basic',
  APIList: { List: 'listProjectContractCategoryBrief', Query: 'queryProjectContractCategory', Add: 'addProjectContractCategory', Modify: 'modifyProjectContractCategory', Delete: 'deleteProjectContractCategory' }
}

export const winProjectContractTemplate = {
  Name: 'ProjectContractTemplate',
  CallName: 'ProjectContractTemplate',
  Type: 'Basic',
  APIList: { List: 'listProjectContractTemplateBrief', Query: 'queryProjectContractTemplate', Add: 'addProjectContractTemplate', Modify: 'modifyProjectContractTemplate', Delete: 'deleteProjectContractTemplate', Switch: 'switchProjectContractTemplate' }
}

export const winProjectInfo = {
  Name: 'ProjectInfo',
  CallName: 'ProjectInfo',
  Type: 'Basic',
  APIList: { List: 'listProjectInfoBrief', Query: 'queryProjectInfo', Add: 'addProjectInfo', Modify: 'modifyProjectInfo', Delete: 'deleteProjectInfo' }
}

export const winProjectLevel = {
  Name: 'ProjectLevel',
  CallName: 'ProjectLevel',
  Type: 'Basic',
  APIList: { List: 'listProjectLevelBrief', Query: 'queryProjectLevel', Add: 'addProjectLevel', Modify: 'modifyProjectLevel', Delete: 'deleteProjectLevel' }
}

export const winProjectEngineeringCategory = {
  Name: 'ProjectEngineeringCategory',
  CallName: 'ProjectEngineeringCategory',
  Type: 'Basic',
  APIList: { List: 'listProjectEngineeringCategoryBrief', Query: 'queryProjectEngineeringCategory', Add: 'addProjectEngineeringCategory', Modify: 'modifyProjectEngineeringCategory', Delete: 'deleteProjectEngineeringCategory', Switch: 'switchProjectEngineeringCategory' }
}

export const winProjectStage = {
  Name: 'ProjectStage',
  CallName: 'ProjectStage',
  Type: 'Basic',
  APIList: { List: 'listProjectStageBrief', Query: 'queryProjectStage', Add: 'addProjectStage', Modify: 'modifyProjectStage', Delete: 'deleteProjectStage', Switch: 'switchProjectStage' }
}

export const winProjectType = {
  Name: 'ProjectType',
  CallName: 'ProjectType',
  Type: 'Basic',
  APIList: { List: 'listProjectTypeBrief', Query: 'queryProjectType', Add: 'addProjectType', Modify: 'modifyProjectType', Delete: 'deleteProjectType' }
}

export const winProjectValidationItem = {
  Name: 'ProjectValidationItem',
  CallName: 'ProjectValidationItem',
  Type: 'Basic',
  APIList: { List: 'listProjectValidationItemBrief', Query: 'queryProjectValidationItem', Add: 'addProjectValidationItem', Modify: 'modifyProjectValidationItem', Delete: 'deleteProjectValidationItem', Import: 'importProjectValidationItem', Reset: 'resetProjectValidationItem' }
}

export const winProjectValidationItemType = {
  Name: 'ProjectValidationItemType',
  CallName: 'ProjectValidationItemType',
  Type: 'Basic',
  APIList: { List: 'listProjectValidationItemTypeBrief', Query: 'queryProjectValidationItemType', Add: 'addProjectValidationItemType', Modify: 'modifyProjectValidationItemType', Delete: 'deleteProjectValidationItemType' }
}

export const winRegion = {
  Name: 'Region',
  CallName: 'Region',
  Type: 'Basic',
  APIList: { List: 'listRegionBrief', Query: 'queryRegion', Add: 'addRegion', Modify: 'modifyRegion', Delete: 'deleteRegion', Switch: 'switchRegion' }
}

export const winSalary = {
  Name: 'Salary',
  CallName: 'Salary',
  Type: 'Basic',
  APIList: { List: 'listSalaryBrief', Query: 'querySalary', Add: 'addSalary', Modify: 'modifySalary', Delete: 'deleteSalary' }
}

export const winSalaryItem = {
  Name: 'SalaryItem',
  CallName: 'SalaryItem',
  Type: 'Basic',
  APIList: { List: 'listSalaryItemBrief', Query: 'querySalaryItem', Add: 'addSalaryItem', Modify: 'modifySalaryItem', Delete: 'deleteSalaryItem', Switch: 'switchSalaryItem' }
}

export const winStockForm = {
  Name: 'StockForm',
  CallName: 'StockForm',
  Type: 'Basic',
  APIList: { List: 'listStockFormBrief', Query: 'queryStockForm', Add: 'addStockForm', Modify: 'modifyStockForm', Delete: 'deleteStockForm', 
    SendToBack: 'sendToBackStockForm', SendToNext: 'sendToNextStockForm', Cancel: 'cancelStockForm', Execute: 'executeStockForm' }
}

export const winStorehouse = {
  Name: 'Storehouse',
  CallName: 'Storehouse',
  Type: 'Basic',
  APIList: { List: 'listStorehouseBrief', Query: 'queryStorehouse', Add: 'addStorehouse', Modify: 'modifyStorehouse', Delete: 'deleteStorehouse' }
}

export const winSupplier = {
  Name: 'Supplier',
  CallName: 'Supplier',
  Type: 'Basic',
  APIList: { List: 'listSupplierBrief', Query: 'querySupplier', Add: 'addSupplier', Modify: 'modifySupplier', Delete: 'deleteSupplier' }
}

/*export const winSupplierSupplyMaterial = {
  Name: 'SupplierSupplyMaterial',
  CallName: 'SupplierSupplyMaterial',
  Type: 'Basic',
  APIList: { List: 'listSupplierSupplyMaterialBrief', Query: 'querySupplierSupplyMaterial', Add: 'addSupplierSupplyMaterial', Modify: 'modifySupplierSupplyMaterial', Delete: 'deleteSupplierSupplyMaterial', Link: 'linkSupplierSupplyMaterial', Unlink: 'unlinkSupplierSupplyMaterial' }
}*/

export const winSupplyMaterial = {
  Name: 'SupplyMaterial',
  CallName: 'SupplyMaterial',
  Type: 'Basic',
  APIList: { List: 'listSupplyMaterialBrief', Query: 'querySupplyMaterial', Add: 'addSupplyMaterial', Modify: 'modifySupplyMaterial', Delete: 'deleteSupplyMaterial', Switch: 'switchSupplyMaterial' }
}

export const winSupplierLevel = {
  Name: 'SupplierLevel',
  CallName: 'SupplierLevel',
  Type: 'Basic',
  APIList: { List: 'listSupplierLevelBrief', Query: 'querySupplierLevel', Add: 'addSupplierLevel', Modify: 'modifySupplierLevel', Delete: 'deleteSupplierLevel' }
}

export const winUser = {
  Name: 'User',
  CallName: 'User',
  Type: 'Basic',
  APIList: { Login: 'login', List: 'lisUserBrief', Query: 'queryUser', Add: 'addUser', Modify: 'modifyUser', Delete: 'deleteUser', Export: 'exportUser' }
}

export const winWorkerLevel = {
  Name: 'WorkerLevel',
  CallName: 'WorkerLevel',
  Type: 'Basic',
  APIList: { List: 'listWorkerLevelBrief', Query: 'queryWorkerLevel', Add: 'addWorkerLevel', Modify: 'modifyWorkerLevel', Delete: 'deleteWorkerLevel' }
}

export const winWorkerProvider = {
  Name: 'WorkerProvider',
  CallName: 'WorkerProvider',
  Type: 'Basic',
  APIList: { List: 'listWorkerProviderBrief', Query: 'queryWorkerProvider', Add: 'addWorkerProvider', Modify: 'modifyWorkerProvider', Delete: 'deleteWorkerProvider' }
}

export const winWorkingCategory = {
  Name: 'WorkingCategory',
  CallName: 'WorkingCategory',
  Type: 'Basic',
  APIList: { List: 'listWorkingCategoryBrief', Query: 'queryWorkingCategory', Add: 'addWorkingCategory', Modify: 'modifyWorkingCategory', Delete: 'deleteWorkingCategory', Switch: 'switchWorkingCategory' }
}

/*export const winWorkerWorkingCategory = {
  Name: 'WorkerWorkingCategory',
  CallName: 'WorkerWorkingCategory',
  Type: 'Basic',
  APIList: { List: 'listWorkerWorkingCategoryBrief', Query: 'queryWorkerWorkingCategory', Add: 'addWorkerWorkingCategory', Modify: 'modifyWorkerWorkingCategory', Delete: 'deleteWorkerWorkingCategory', Link: 'linkWorkerWorkingCategory', Unlink: 'unlinkWorkerWorkingCategory' }
}*/

export const winZone = {
  Name: 'Zone',
  CallName: 'Zone',
  Type: 'Basic',
  APIList: { List: 'listZoneBrief', Query: 'queryZone', Add: 'addZone', Modify: 'modifyZone', Delete: 'deleteZone' }
}

// --------------------------------------------------- System Parameters ---------------------------------------------------
export const winSysAccountCategory = {
  Name: 'SysAccountCategory',
  CallName: 'Account Category',
  Type: 'System',
  APIList: { List: 'listSysAccountCategoryBrief' }
}

export const winSysAccountFlow = {
  Name: 'SysAccountFlow',
  CallName: 'Account Flow',
  Type: 'System',
  APIList: { List: 'listSysAccountFlowBrief' }
}

export const winSysAccountObjectType = {
  Name: 'SysAccountObjectType',
  CallName: 'Account Object Type',
  Type: 'System',
  APIList: { List: 'listSysAccountObjectTypeBrief' }
}

export const winSysAccountType = {
  Name: 'SysAccountType',
  CallName: 'Account Type',
  Type: 'System',
  APIList: { List: 'listSysAccountTypeBrief' }
}

export const winSysColor = {
  Name: 'SysColor',
  CallName: 'Color',
  Type: 'System',
  APIList: { List: 'listSysColorBrief' }
}

export const winSysFormType = {
  Name: 'SysFormType',
  CallName: 'SysFormType',
  Type: 'System',
  APIList: { List: 'listSysFormTypeBrief' }
}

export const winSysInvoiceFormat = {
  Name: 'SysInvoiceFormat',
  CallName: 'SysInvoiceFormat',
  Type: 'System',
  APIList: { List: 'listSysInvoiceFormatBrief' }
}

export const winSysPaymentType = {
  Name: 'SysPaymentType',
  CallName: 'SysPaymentType',
  Type: 'System',
  APIList: { List: 'listSysPaymentTypeBrief' }
}

export const winSysProgressStatusLevelType = {
  Name: 'SysProgressStatusLevelType',
  CallName: 'Progress Status Type',
  Type: 'System',
  APIList: { List: 'listSysProgressStatusLevelTypeBrief' }
}

export const winSysProgressStatus = {
  Name: 'SysProgressStatus',
  CallName: 'SysProgressStatus',
  Type: 'System',
  APIList: { List: 'listSysProgressStatusBrief' }
}

export const winSysProjectEngineeringType = {
  Name: 'SysProjectEngineeringType',
  CallName: 'SysProjectEngineeringType',
  Type: 'System',
  APIList: { List: 'listSysProjectEngineeringTypeBrief' }
}

export const winSysProjectContractPricingCategory = {
  Name: 'SysProjectContractPricingCategory',
  CallName: 'SysProjectContractPricingCategory',
  Type: 'System',
  APIList: { List: 'listSysProjectContractPricingCategoryBrief' }
}

export const winSysProjectViewType = {
  Name: 'SysProjectViewType',
  CallName: 'SysProjectViewType',
  Type: 'System',
  APIList: { List: 'listSysProjectViewTypeBrief' }
}

export const winSysSalaryCategory = {
  Name: 'SysSalaryCategory',
  CallName: 'SysSalaryCategory',
  Type: 'System',
  APIList: { List: 'listSysSalaryCategoryBrief' }
}

export const winSysStorehouseType = {
  Name: 'SysStorehouseType',
  CallName: 'Storehouse Type',
  Type: 'System',
  APIList: { List: 'listSysStorehouseTypeBrief' }
}

export const winSysSupplierPaymentMethod = {
  Name: 'SysSupplierPaymentMethod',
  CallName: 'Payment Method',
  Type: 'System',
  APIList: { List: 'listSysSupplierPaymentMethodBrief' }
}

export const winSysTextLayer = {
  Name: 'SysTextLayer',
  CallName: 'TextLayer',
  Type: 'System',
  APIList: { List: 'listSysTextLayerBrief' }
}

export const winStatisticProjectProgress = {
  Name: 'ProjectProgress',
  CallName: 'ProjectProgress',
  Type: 'Statistic',
  APIList: { Query: 'queryStatisticProjectProgress' }
}

export const winStatisticProjectProgress_I = {
  Name: 'ProjectProgress_I',
  CallName: 'ProjectProgress_I',
  Type: 'Statistic',
  APIList: { Query: 'queryStatisticProjectProgress_I' }
}

export const winStatisticProjectProgress_I2 = {
  Name: 'ProjectProgress_I2',
  CallName: 'ProjectProgress_I2',
  Type: 'Statistic',
  APIList: { Query: 'queryStatisticProjectProgress_I2' }
}

export const winStatisticProjectProgress_P = {
  Name: 'ProjectProgress_P',
  CallName: 'ProjectProgress_P',
  Type: 'Statistic',
  APIList: { Query: 'queryStatisticProjectProgress_P' }
}

export const winVBrandApplication = {
  Name: 'BrandApplication',
  CallName: 'BrandApplication',
  Type: 'Basic',
  APIList: { List: 'listBrandApplicationBrief', Query: 'queryBrandApplication' }
}

export const winVCompanyApplication = {
  Name: 'CompanyApplication',
  CallName: 'CompanyApplication',
  Type: 'Basic',
  APIList: { List: 'listCompanyApplicationBrief', Query: 'queryCompanyApplication' }
}

// --------------------------------------------------- Specific ------------------------------------------------------------
export const winAPermissionOwner = {
  Name: 'PermissionOwner',
  CallName: 'PermissionOwner',
  Type: 'Basic',
  APIList: { List: 'listPermissionOwnerBrief', Query: 'queryPermissionOwner' }
}

export const winAProjectBalance = {
  Name: 'ProjectBalance',
  CallName: 'ProjectBalance',
  Type: 'Basic',
  APIList: { List: 'listProjectBalanceBrief', Query: 'queryProjectBalance' }
}

export const winATask = {
  Name: 'Task',
  CallName: 'Task',
  Type: 'Basic',
  APIList: { List: 'listTaskBrief', Query: 'queryTask' }
}
