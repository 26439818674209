export const PROFILE_ADD = 'PROFILE_ADD';
export const PROFILE_CLEAN = 'PROFILE_CLEAN';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';

export const TABLE_INIT = 'TABLE_INIT';
export const TABLE_STORE = 'TABLE_STORE';

export const TASK_INIT = 'TASK_INIT';
export const TASK_STORE = 'TASK_STORE';

